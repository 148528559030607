import React from "react";
import canUseDOM from 'can-use-dom';
import { graphql } from 'gatsby';
import SEO from "@/components/seo";
import Layout from "@/components/layout";
import GalleryNav from "@/components/GalleryNav";
import GalleryList from "@/components/GalleryList";
import GalleryListHeader from "@/components/GalleryListHeader";

const PAGE = {
  pageTitle: 'KOJI OTANI | Gallery',
  sectionTitle: '...',
  pageDescription: '...',
  headerColor: 'white',
}

const GalleryListPage = ({ props }) => {
  const countrys = props.data.countrys.edges.map(v => v.node);
  const region = props.data.region;
  const regionItems = countrys.filter(v => v.region.id === region.id);

  return (
    <Layout page={PAGE}>
      <SEO title={PAGE.pageTitle} />
      <GalleryNav />
      <GalleryListHeader
        title={region.name}
      />
      <GalleryList items={regionItems.reverse()} type="countrys" />
    </Layout>
  );
};

const GalleryListPageContainer = (props) => {
  if (
    props && props.data && props.data.region &&
    props.data.countrys && props.data.countrys &&
    canUseDOM
  ) {
    return (
      <GalleryListPage props={props} />
    );
  } else {
    return (
      <div>loading...</div>
    )
  };
};

export default GalleryListPageContainer;

export const query = graphql`
  query($id: String) {
    region: regionsJson(id: { eq: $id } ) {
      id
      name
    }
    countrys: allCountrysJson(filter: { region: { id: { eq: $id } } }) {
      edges {
        node {
          id
          name
          cover
          region {
            id
            name
          }
        }
      }
    }
  }
`
